<template>
    <div class="slider-modal modal-bordered">
        <div class="slider-modal_header" :style="{background: params ? `url(assets/img/land-slide${ params.index }.png)` : ''}"></div>
        <div class="slider-modal_body">
            <div class="slider-modal_body_content">
                <h1 class="slider-modal_title" v-html="$content(`landing_slider_slideModal${ $store.getters.activeModalParams.index }_title`)"></h1>
                <p v-html="$content(`landing_slider_slideModal${ $store.getters.activeModalParams.index }_text`)"></p>
                <div class="slider-modal_btn">
                    <ProjectButton
                        variant="app"
                        radius="10"
                        @click.native="onBtnClick">
                        <div class="w-100" v-html="$content('header_registerFormSubmit')"></div>
                    </ProjectButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectButton from "@/components/Buttons/ProjectButton";
export default {
name: "Slider",
    components: {ProjectButton},
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        }
    },
    methods: {
        onBtnClick() {
            this.$modal.close()
                .then(() => this.$store.dispatch('togglePopoverWithForm', {
                    popover: 'auth',
                    form: 'registration'
                }))
                // .then(() => this.$store.dispatch('togglePopover', 'auth'))
        }
    }
}
</script>

<style lang="scss">
    @import "../../styles/mixins";

    .slider-modal {
        background: white;
        padding-bottom: 25px;
        overflow-x: hidden;

        &_title {
            font-family: Montserrat-SemiBold, sans-serif;
            color: var(--main-titles);
            font-size: 24px;
            text-align: center;
        }

        &_header {
            height: 206px;
            background-size: cover !important;
            background-position: top center !important;
        }

        &_btn {
            width: 394px !important;

            @include only-xs {
                width: 100% !important;
            }

            & button {
                width: 100%;
            }
        }

        &_body {
            display: flex;
            justify-content: center;

            @include only-xs {
                width: 90%;
                margin: 0 auto;
            }

            &_content {
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include only-xs {
                    width: 100%;
                }
            }

            & h1 {
                margin: 44px 0;
            }

            & p {
                color: var(--main-dark-paper);
                font-family: Montserrat-Regular, sans-serif;
                font-size: 14px;
                text-align: initial;
            }
        }
    }
</style>